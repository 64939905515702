const demoFormSubmit = (formValidator) => {
  const form = document.querySelector('.signup-form');

  form.addEventListener('submit', (event) => {
    event.preventDefault();

    const isFormValid = formValidator.validateForm(true);

    if (!isFormValid) {
      return;
    }

    const { value: email } = document.querySelector('input[name="email"]') ?? '—';
    const { value: phone } = document.querySelector('input[name="phone"]') ?? '—';
    const { value: password } = document.querySelector('input[name="password"]') ?? '—';
    const { value: currency } = document.querySelector('select[name="currency"]') ?? '—';

    const privacy = document.querySelector('input[name="privacy"]').checked ? 'on' : 'off';
    const mailing = document.querySelector('input[name="mailing"]').checked ? 'on' : 'off';

    const exampleFormData = document.querySelector('.example-form-data');

    exampleFormData.innerHTML = '';

    const data = {
      Email: email || '—',
      Phone: phone || '—',
      Password: password || '—',
      Currency: currency || '—',
      Privacy: privacy,
      Mailing: mailing,
    };

    Object.entries(data).forEach(([key, value]) => {
      const para = document.createElement('p');

      para.textContent = `${key}: ${value}`;
      exampleFormData.appendChild(para);
    });

    document.body.classList.add('is-form-demo-sent');
  });
};

export default demoFormSubmit;
