class HeightTracker {
  constructor(selector) {
    this.elements = document.querySelectorAll(selector);
  }

  setHeightVariable() {
    const heights = Array.from(this.elements).map((el) => el.offsetHeight);
    const maxHeight = Math.max(...heights);

    document.body.style.setProperty('--height-track', `${maxHeight}px`);
  }

  init() {
    if (!this.elements.length) {
      return;
    }

    this.resizeObserver = new ResizeObserver(() => this.setHeightVariable());
    this.elements.forEach((element) => this.resizeObserver.observe(element));
    this.setHeightVariable();
  }
}

export default HeightTracker;
