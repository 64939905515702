import SelectOptions from 'select-options';
import Tickbox from 'tickbox';
import FormValidator from './FormValidator';
import demoFormSubmit from './demoFormSubmit'; // TODO delete this
import SignupTabs from './SignupTabs';

const signupHandler = {
  selectOptions: new SelectOptions({ mobileMode: true }),
  tickbox: new Tickbox(),
  formValidator: new FormValidator(),
  signupTabs: new SignupTabs(),

  async initFormElems() {
    const elems = [this.selectOptions, this.tickbox];
    await Promise.all(elems.map((elem) => elem.init()));
  },

  async init() {
    await this.initFormElems();
    this.formValidator.setPhoneMask();
    this.formValidator.addRealTimeValidation();
    this.signupTabs.init();
    demoFormSubmit(this.formValidator); // TODO delete this
  },
};

export default signupHandler;
