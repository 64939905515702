import { MaskInput } from 'maska';

class FormValidator {
  constructor() {
    this.maska = null;
    this.emailInput = document.querySelector('input[name="email"]');
    this.phoneInput = document.querySelector('input[name="phone"]');
    this.passwordInput = document.querySelector('input[name="password"]');
    this.acceptTermsInput = document.querySelector('input[name="privacy"]');
  }

  static validateEmail(emailInput, showError = false) {
    if (!emailInput || emailInput.disabled) return true;

    const email = emailInput.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const parent = emailInput.parentElement;

    emailInput.setCustomValidity('');

    if (!emailRegex.test(email)) {
      if (showError) {
        const errorMessage = emailInput.getAttribute('data-error-message') ?? 'Invalid email';

        parent?.classList.add('error');
        emailInput.setCustomValidity(errorMessage);
        emailInput.reportValidity();

        parent?.classList.remove('passed');
      }
      return false;
    }

    emailInput.setCustomValidity('');
    emailInput.reportValidity();
    parent?.classList.remove('error');
    parent?.classList.add('passed');

    return true;
  }

  static validatePhone(phoneInput, showError = false) {
    if (!phoneInput || phoneInput.disabled) return true;

    const phone = phoneInput.value;
    const parent = phoneInput.parentElement;

    phoneInput.setCustomValidity('');

    if (phone.length !== phoneInput.getAttribute('data-maska').length) {
      if (showError) {
        const errorMessage = phoneInput.getAttribute('data-error-message') ?? 'Invalid phone number';

        parent?.classList.add('error');
        phoneInput.setCustomValidity(errorMessage);
        phoneInput.reportValidity();

        parent?.classList.remove('passed');
      }
      return false;
    }

    phoneInput.setCustomValidity('');
    phoneInput.reportValidity();
    parent?.classList.remove('error');
    parent?.classList.add('passed');

    return true;
  }

  static validatePassword(passwordInput, showError = false) {
    if (!passwordInput || passwordInput.disabled) return true;

    const password = passwordInput.value;
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{6,}$/;
    const parent = passwordInput.parentElement;

    passwordInput.setCustomValidity('');

    if (!passwordRegex.test(password)) {
      if (showError) {
        const errorMessage = passwordInput.getAttribute('data-error-message') ?? 'Invalid password';

        parent?.classList.add('error');
        passwordInput.setCustomValidity(errorMessage);
        passwordInput.reportValidity();

        parent?.classList.remove('passed');
      }
      return false;
    }

    passwordInput.setCustomValidity('');
    passwordInput.reportValidity();
    parent?.classList.remove('error');
    parent?.classList.add('passed');

    return true;
  }

  static validatePrivacy(acceptTermsInput, showError = false) {
    if (!acceptTermsInput || acceptTermsInput.disabled) return true;

    const parent = acceptTermsInput.parentElement;

    acceptTermsInput.setCustomValidity('');

    if (!acceptTermsInput.checked) {
      if (showError) {
        const errorMessage = acceptTermsInput.getAttribute('data-error-message') ?? 'You must accept the terms';

        parent?.classList.add('error');
        acceptTermsInput.setCustomValidity(errorMessage);
        acceptTermsInput.reportValidity();

        parent?.classList.remove('passed');
      }
      return false;
    }

    acceptTermsInput.setCustomValidity('');
    acceptTermsInput.reportValidity();
    parent?.classList.remove('error');
    parent?.classList.add('passed');

    return true;
  }

  setPhoneMask() {
    const phonePrefixSelector = document.querySelector('.select-option--prefix select');

    if (this.phoneInput instanceof HTMLInputElement) {
      this.maska = new MaskInput(this.phoneInput, {
        mask: this.phoneInput.getAttribute('data-maska') ?? '',
      });

      phonePrefixSelector?.addEventListener('change', () => {
        const selectedOption = phonePrefixSelector.options[phonePrefixSelector.selectedIndex];
        const newPrefix = selectedOption?.value ?? '';
        const newPlaceholder = `${newPrefix} (XXX) XXX-XX-XX`;
        const newMaska = `${newPrefix} (###) ###-##-##`;

        this.phoneInput.setAttribute('placeholder', newPlaceholder);
        this.phoneInput.setAttribute('data-maska', newMaska);

        this.phoneInput.value = '';

        this.maska.destroy();

        this.maska = new MaskInput(this.phoneInput, {
          mask: newMaska,
        });

        FormValidator.validatePhone(this.phoneInput);
      });

      this.phoneInput.addEventListener('input', () => FormValidator.validatePhone(this.phoneInput));
    }
  }

  validateForm(showError = false) {
    const isEmailValid = FormValidator.validateEmail(this.emailInput, showError);
    const isPhoneValid = FormValidator.validatePhone(this.phoneInput, showError);
    const isPasswordValid = FormValidator.validatePassword(this.passwordInput, showError);
    const isPrivacyValid = FormValidator.validatePrivacy(this.acceptTermsInput, showError);

    return isEmailValid && isPhoneValid && isPasswordValid && isPrivacyValid;
  }

  addRealTimeValidation() {
    this.emailInput?.addEventListener('input', () => FormValidator.validateEmail(this.emailInput));
    this.passwordInput?.addEventListener('input', () => FormValidator.validatePassword(this.passwordInput));
    this.phoneInput?.addEventListener('input', () => FormValidator.validatePhone(this.phoneInput));
    this.acceptTermsInput?.addEventListener('change', () => FormValidator.validatePrivacy(this.acceptTermsInput));
  }
}

export default FormValidator;
