import { lock, unlock } from 'tua-body-scroll-lock';
import DialogLite from 'dialog-lite';
import signupHandler from './utils/signupHandler';
import HeightTracker from './utils/HeightTracker';
import AnimateBias from './utils/AnimateBias';

const App = {
  heightTracker: new HeightTracker('.height-track'),
  animateBias: new AnimateBias(),

  initScrollLock() {
    const authForm = document.querySelector('.auth-form');

    if (authForm instanceof HTMLElement) {
      if (document.body.classList.contains('is-taking-bonus')) {
        lock(authForm);
      } else {
        unlock(authForm);
      }
    }
  },

  initTakingBonus() {
    const button = document.querySelector('button.btn--take-bonus');

    button?.addEventListener('pointerdown', () => {
      document.body.classList.add('is-taking-bonus');
    });
  },

  dialogBox() {
    const dialogLite = new DialogLite({
      closingButton: true,
      closingBackdrop: true,
    });

    dialogLite.init();

    const button = document.querySelector('[data-dialog="sport-bonus"]');

    button?.addEventListener('pointerdown', () => {
      dialogLite.open({
        stylingClass: 'dialog-lite--sport-bonus',
      });
    });
  },

  async init() {
    this.initScrollLock();
    this.initTakingBonus();
    this.heightTracker.init();
    this.animateBias.init();
    this.dialogBox();
    await signupHandler.init();
  },
};

document.addEventListener('DOMContentLoaded', () => App.init());
