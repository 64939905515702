class SignupTabs {
  constructor() {
    this.tabs = document.querySelectorAll('.signup-tabs__item');

    this.formItems = {
      email: document.querySelectorAll('[data-form="email"], [data-form="password"], [data-form="currency"]'),
      phone: document.querySelectorAll('[data-form="phone"], [data-form="password"], [data-form="currency"]'),
      oneclick: document.querySelectorAll('[data-form="currency"]'),
    };

    this.init();
  }

  init() {
    this.tabs.forEach((tab) => {
      tab.addEventListener('click', (event) => this.handleTabClick(event));
    });

    this.updateFormFieldsVisibility();
  }

  handleTabClick(event) {
    this.tabs.forEach((tab) => tab.classList.remove('is-active'));

    event.currentTarget.classList.add('is-active');

    this.updateFormFieldsVisibility();
  }

  updateFormFieldsVisibility() {
    const activeTab = document.querySelector('.signup-tabs__item.is-active');
    const activeTabType = activeTab?.dataset.tab;

    this.hideAllFormItems();

    if (activeTabType === 'email') {
      SignupTabs.showFormItems(this.formItems.email);
    } else if (activeTabType === 'phone') {
      SignupTabs.showFormItems(this.formItems.phone);
    } else if (activeTabType === 'oneclick') {
      SignupTabs.showFormItems(this.formItems.oneclick);
    }
  }

  hideAllFormItems() {
    Object.values(this.formItems).forEach((items) => {
      items.forEach((item) => SignupTabs.hideFormItem(item));
    });
  }

  static hideFormItem(item) {
    const element = item;

    element.style.display = 'none';

    element.querySelectorAll('input, select, textarea, button').forEach((input) => input.setAttribute('disabled', 'disabled'));
  }

  static showFormItems(items) {
    items.forEach((item) => {
      const element = item;

      element.style.display = 'block';

      element.querySelectorAll('input, select, textarea, button').forEach((input) => input.removeAttribute('disabled'));
    });
  }
}

export default SignupTabs;
