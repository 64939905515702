class AnimateBias {
  constructor() {
    this.min = -15;
    this.max = 20;
    this.speed = 0.005;
    this.range = (this.max - this.min) / 2;
    this.midpoint = (this.max + this.min) / 2;
    this.progress = 0;
    this.lastBias = { x: null, y: null };
  }

  updateVariables = () => {
    const sinProgress = Math.sin(this.progress);
    const cosProgress = Math.cos(this.progress);

    const biases = {
      y: (this.midpoint + this.range * sinProgress).toFixed(1),
      x: (this.midpoint + this.range * cosProgress).toFixed(1),
    };

    Object.keys(biases).forEach((axis) => {
      const value = biases[axis];

      if (value !== this.lastBias[axis]) {
        document.body.style.setProperty(`--bias-${axis}`, value);

        this.lastBias[axis] = value;
      }
    });

    this.progress += this.speed;

    requestAnimationFrame(this.updateVariables);
  };

  init() {
    requestAnimationFrame(this.updateVariables);
  }
}

export default AnimateBias;
